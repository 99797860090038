/* eslint-disable react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '@/components/Seo'
import Blocks from '@/utils/Blocks'
import { cleanProps } from '@/utils'
import useConstant from '@/hooks/useConstant'
import { HrBlock } from '@/components/Bamboo'
/* eslint-disable no-unused-vars */
import { pageQuery } from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function Join({
	pageContext: { media, wordpress_id },
	data: {
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(() => cleanProps(blocks, media))

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={data} media={media} currentPage={wordpress_id} />
		</>
	)
}

Join.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired
	})
}

export const query = graphql`
	query JoinQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
	}
`

export default Join
